var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext } from 'react';
import './styles.css';
import { TextField, Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import FormContext from './FormContext';
var getDefaultValue = function (type) {
    return ({
        text: '',
        date: '2000-01-01',
        number: 0,
        boolean: false,
    }[type]);
};
var formatValue = function (value, type) {
    if (type === 'number') {
        return Number(value);
    }
    if (type === 'boolean') {
        return Boolean(value);
    }
    return value;
};
var CustomCheckbox = function (_a) {
    var _b;
    var name = _a.name, type = _a.type, label = _a.label, props = _a.props;
    var _c = useContext(FormContext), form = _c.form, setProperty = _c.setProperty;
    var onChange = function (e) {
        setProperty(name, formatValue(e.target.checked, type));
    };
    var checkProps = props || {};
    return _jsx(FormControlLabel, { control: _jsx(Checkbox, __assign({ checked: (_b = form[name]) !== null && _b !== void 0 ? _b : getDefaultValue(type), onChange: onChange, name: name }, checkProps), void 0), label: label }, void 0);
};
var CustomTextField = function (_a) {
    var _b;
    var name = _a.name, type = _a.type, label = _a.label, props = _a.props;
    var _c = useContext(FormContext), form = _c.form, setProperty = _c.setProperty;
    var onChange = function (e) {
        setProperty(name, formatValue(e.target.value, type));
    };
    var typeProps = {};
    if (type === 'date' || type === 'datetime-local') {
        typeProps = {
            InputLabelProps: { shrink: true },
        };
    }
    var fieldProps = props || {};
    return _jsx(TextField, __assign({ type: type, name: name, label: label, value: (_b = form[name]) !== null && _b !== void 0 ? _b : getDefaultValue(type), onChange: onChange, fullWidth: true }, typeProps, fieldProps), void 0);
};
var getInput = function (type, props) {
    switch (type) {
        case 'boolean':
            return _jsx(CustomCheckbox, __assign({}, props), void 0);
        default:
            return _jsx(CustomTextField, __assign({}, props), void 0);
    }
};
var CustomInput = function (props) {
    var type = props.type, gridItem = props.gridItem;
    if (gridItem) {
        return (_jsx(Grid, __assign({ item: true }, props, { children: getInput(type, props) }), void 0));
    }
    return getInput(type, props);
};
export var CustomInputElement = function (_a) {
    var _b;
    var name = _a.name, type = _a.type, label = _a.label, render = _a.render;
    var _c = useContext(FormContext), form = _c.form, setProperty = _c.setProperty;
    var onChange = function (value) {
        setProperty(name, formatValue(value, type));
    };
    return _jsx(_Fragment, { children: render((_b = form[name]) !== null && _b !== void 0 ? _b : getDefaultValue(type), onChange) }, void 0);
};
export default CustomInput;
