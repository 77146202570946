var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useRef, useState } from 'react';
import './styles.css';
import { List, ListItem, ListItemText, ListItemIcon, Button } from '@material-ui/core';
var FileInput = function (_a) {
    var name = _a.name, onChange = _a.onChange, style = _a.style;
    var _b = useState([]), files = _b[0], setFiles = _b[1];
    var inputRef = useRef(null);
    var onClick = function () {
        if (inputRef && inputRef.current) {
            inputRef.current.click();
        }
    };
    var changeHandler = function (e) {
        onChange && onChange(e);
        setFiles(__spreadArray([], e.target.files, true));
    };
    var displayText = (function () {
        if (!files || !files.length) {
            return 'No file(s) chosen';
        }
        else if (files.length <= 1) {
            return files[0].name;
        }
        else {
            return "".concat(files.length, " files");
        }
    })();
    return (_jsxs(_Fragment, { children: [_jsx("input", { type: "file", ref: inputRef, name: name, onChange: changeHandler, className: "hidden", multiple: true }, void 0), _jsx(List, __assign({ style: style }, { children: _jsxs(ListItem, { children: [_jsx(ListItemIcon, { children: _jsx(Button, __assign({ variant: "contained", onClick: onClick }, { children: "Choose Files" }), void 0) }, void 0), _jsx(ListItemText, { primary: displayText, style: { marginLeft: '0.5em' } }, void 0)] }, void 0) }), void 0)] }, void 0));
};
export default FileInput;
