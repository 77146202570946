var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { FILES_LIVE, FILES_LOCAL, FILES_PREVIEW } from '../constants';
export var getEmptyMstData = function () { return ({
    base_local: FILES_LOCAL,
    base_preview: FILES_PREVIEW,
    base_live: FILES_LIVE,
    tokens: [],
    links: [],
    references: [],
    albums: [],
}); };
export var getEmptyMstToken = function (id) {
    if (id === void 0) { id = ''; }
    return ({
        id: id,
        enabled: true,
        token: '',
        tag: '',
        description: '',
        label: '',
        unlockPhotos: true,
        showLabel: false,
        showExclusive: false,
        showSelectedOnly: false,
        showLockedItems: true,
        showUpselling: true,
        showDates: true,
        include: [],
        exclude: [],
    });
};
export var getEmptyMstLink = function (id) {
    if (id === void 0) { id = ''; }
    return ({
        id: id,
        enabled: true,
        text: '',
        url: '',
        logo: 'link',
    });
};
export var getEmptyMstReference = function (id) {
    if (id === void 0) { id = ''; }
    return ({
        id: id,
        enabled: true,
        text: '',
        model_name: '',
        model_url: '',
        img_album: '',
        img_photo: '',
        img_src: '',
    });
};
export var getEmptyMstAlbum = function (id) {
    if (id === void 0) { id = ''; }
    return ({
        id: id,
        name: '',
        title: '',
        description: '',
        enabled: true,
        portfolio: true,
        vip: false,
        blurred: false,
        vaulted: false,
        exclusive: false,
        tags: [],
        shootDate: '',
        postDate: '',
        model_name: '',
        model_instagram: '',
        model_email: '',
        model_website: '',
        model_website_name: '',
        thumb_id: '',
        thumb_x: 0,
        thumb_y: 0,
        thumb_size: 1,
        thumb_src: '',
        blurred_thumb_src: '',
        photos: [],
    });
};
export var getEmptyMstPhoto = function (id) {
    if (id === void 0) { id = ''; }
    return ({
        id: id,
        enabled: true,
        blurred: false,
        exclusive: false,
        isNSFW: false,
        thumb_x: 0,
        thumb_y: 0.126,
        thumb_size: 1,
        name: '',
        originalName: '',
        src_full: '',
        src_thumb: '',
        src_blurred: '',
        src_blurred_thumb: '',
    });
};
export var cloneMstData = function (data) { return (__assign(__assign({}, data), { tokens: cloneMstDataTokens(data.tokens), links: cloneMstDataLinks(data.links), references: cloneMstDataReferences(data.references), albums: cloneMstDataAlbums(data.albums) })); };
export var cloneMstDataTokens = function (tokens) { return tokens.map(cloneMstDataToken); };
export var cloneMstDataToken = function (token) { return (__assign(__assign({}, token), { include: __spreadArray([], token.include, true), exclude: __spreadArray([], token.exclude, true) })); };
export var cloneMstDataLinks = function (links) { return links.map(cloneDataLink); };
export var cloneDataLink = function (link) { return (__assign({}, link)); };
export var cloneMstDataReferences = function (references) { return references.map(cloneMstDataReference); };
export var cloneMstDataReference = function (reference) { return (__assign({}, reference)); };
export var cloneMstDataAlbums = function (albums) { return albums.map(cloneMstDataAlbum); };
export var cloneMstDataAlbum = function (album) { return (__assign(__assign({}, album), { photos: cloneMstDataPhotos(album.photos) })); };
export var cloneMstDataPhotos = function (photos) { return photos.map(cloneMstDataPhoto); };
export var cloneMstDataPhoto = function (photo) { return (__assign({}, photo)); };
