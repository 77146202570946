var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
var ListItemLink = function (props) {
    var icon = props.icon, primary = props.primary, to = props.to;
    var renderLink = React.useMemo(function () { return React.forwardRef(function (itemProps, ref) { return _jsx(NavLink, __assign({ exact: true, to: to, ref: ref, activeClassName: "activeListItem" }, itemProps), void 0); }); }, [to]);
    return (_jsx("li", { children: _jsxs(ListItem, __assign({ button: true, component: renderLink }, { children: [icon ? _jsx(ListItemIcon, { children: icon }, void 0) : null, _jsx(ListItemText, { primary: primary, className: "link-item-text" }, void 0)] }), void 0) }, void 0));
};
export default ListItemLink;
