var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { Box, Button, ButtonGroup } from '@material-ui/core';
import { Check as CheckIcon, NotInterested as NotInterestedIcon } from '@material-ui/icons';
/**
 *
 * Example
 *
 * const filtEnabled = useFilterControl('unique-key', null);
 *
 * const filtered = items.filter(filterByControl(filtEnabled, 'enabled'));
 *
 * return <>
 *    <BooleanFilterControl state={filtEnabled} on={<Icon />} off={<Icon />} />
 *    {filtered.map(item => <></>)}
 * </>
 */
var BooleanFilterControl = function (_a) {
    var state = _a.state, label = _a.label, on = _a.on, off = _a.off, color = _a.color, conditionalColor = _a.conditionalColor;
    var value = state[0], setValue = state[1];
    var onClick = function (newValue) { return function () {
        if (newValue !== value) {
            setValue(newValue);
        }
    }; };
    var getSelectedStyle = function (btnValue) {
        var chosenColor = color && (!conditionalColor || btnValue === true) ? color : '64, 64, 64';
        return {
            backgroundColor: "rgba(".concat(chosenColor, ", 0.2)"),
            color: "rgba(".concat(chosenColor, ", 1)"),
        };
    };
    return (_jsxs(Box, __assign({ display: "inline-flex", alignItems: "center", margin: " 0.1em 0.75em 0.1em 0" }, { children: [!!label && (_jsx(Box, __assign({ marginRight: "0.5em", whiteSpace: "nowrap" }, { children: label }), void 0)), _jsxs(ButtonGroup, __assign({ size: "small" }, { children: [_jsx(Button, __assign({ onClick: onClick(false), title: "Don't filter".concat(label ? ' by ' + label : ''), style: value === false ? getSelectedStyle(false) : {} }, { children: off || _jsx(NotInterestedIcon, {}, void 0) }), void 0), _jsx(Button, __assign({ onClick: onClick(true), title: "Filter".concat(label ? ' by ' + label : ''), style: value === true ? getSelectedStyle(true) : {} }, { children: on || _jsx(CheckIcon, {}, void 0) }), void 0)] }), void 0)] }), void 0));
};
export default memo(BooleanFilterControl);
