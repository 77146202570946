var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { IconButton } from '@material-ui/core';
var IconCheckbox = function (_a) {
    var checked = _a.checked, onChange = _a.onChange, on = _a.on, off = _a.off, disabled = _a.disabled, className = _a.className, color = _a.color, conditionalColor = _a.conditionalColor, edge = _a.edge;
    var onClick = function () { return onChange(!checked); };
    return (_jsx(_Fragment, { children: _jsx(IconButton, __assign({ onClick: onClick, className: className, color: !conditionalColor || checked ? color : undefined, edge: edge, disabled: disabled }, { children: checked ? on : off }), void 0) }, void 0));
};
export default IconCheckbox;
