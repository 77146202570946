var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Dialog, Button, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Box } from '@material-ui/core';
import { Close as CloseIcon, FileCopyOutlined as CopyIcon } from '@material-ui/icons';
import OpenLinkBtn from '@client/components/OpenLinkBtn/OpenLinkBtn';
import ReorderList from '@client/components/ReorderList/ReorderList';
import { CopyableTextField } from './CopyableTextField';
import './styles.css';
export var ButtonVariant;
(function (ButtonVariant) {
    ButtonVariant["BUTTON"] = "button";
    ButtonVariant["ICON"] = "icon";
})(ButtonVariant || (ButtonVariant = {}));
var getHTMLAnchorCode = function (_a) {
    var url = _a.url, label = _a.label;
    return "<a href=\"".concat(url, "\">").concat(label || url, "</a>");
};
var getInitialState = function (_a) {
    var url = _a.url, urls = _a.urls, label = _a.label, labels = _a.labels, items = _a.items;
    if (items) {
        return items.map(function (item) { return (__assign(__assign({}, item), { id: item.url, enabled: item.enabled === undefined ? true : item.enabled })); });
    }
    urls = urls || [url];
    labels = labels || [label];
    return urls.map(function (url, index) { return ({ id: uuid(), enabled: true, url: url, label: labels === null || labels === void 0 ? void 0 : labels[index] }); });
};
var CopyLinkModal = function (props) {
    var btnContent = props.btnContent, edge = props.edge;
    var variant = props.variant || ButtonVariant.BUTTON;
    var _a = useState(false), open = _a[0], setOpen = _a[1];
    var _b = useState(getInitialState(props)), items = _b[0], setItems = _b[1];
    useEffect(function () {
        setItems(getInitialState(props));
    }, [props, setItems]);
    var handleOpen = function () { return setOpen(true); };
    var handleClose = function () { return setOpen(false); };
    var finalItems = items.length > 1 ? items.filter(function (_a) {
        var enabled = _a.enabled;
        return enabled;
    }) : items;
    return (_jsxs(_Fragment, { children: [variant === ButtonVariant.BUTTON && (_jsx(Button, __assign({ onClick: handleOpen, className: "copy-link-button" }, { children: btnContent || 'Copy Link' }), void 0)), variant === ButtonVariant.ICON && (_jsx(IconButton, __assign({ edge: edge, onClick: handleOpen, className: "copy-link-iconbtn" }, { children: btnContent || _jsx(CopyIcon, {}, void 0) }), void 0)), _jsxs(Dialog, __assign({ open: open, onClose: handleClose, maxWidth: "md", PaperProps: { className: 'copy-link-modal', style: { padding: '0.5em' } } }, { children: [_jsx(List, __assign({ style: { paddingBottom: 0 } }, { children: _jsxs(ListItem, { children: [_jsx(ListItemText, { children: "Copy Link" }, void 0), _jsx(ListItemSecondaryAction, { children: _jsx(IconButton, __assign({ edge: "end", onClick: handleClose }, { children: _jsx(CloseIcon, {}, void 0) }), void 0) }, void 0)] }, void 0) }), void 0), items.length > 1 && (_jsx(ReorderList, { stickyKey: "copylinkmodal", items: items, showEnabled: items.length > 1, showMove: items.length > 1, showAdd: false, showRemove: false, itemBtns: function (link, index) { return (_jsx(_Fragment, { children: _jsx(OpenLinkBtn, { url: link.url, title: "Open ".concat(link.label || 'link') }, void 0) }, void 0)); }, itemFields: function (link, index) { return (_jsxs(_Fragment, { children: [_jsx(CopyableTextField, { label: link.label || 'URL', value: link.url }, void 0), _jsx(CopyableTextField, { value: getHTMLAnchorCode(link) }, void 0)] }, void 0)); }, onChange: function (newItems) { return setItems(newItems); } }, void 0)), _jsxs(Box, __assign({ display: "flex", width: "100%", padding: "0 0.1em" }, { children: [_jsx(CopyableTextField, { label: "URLs", rows: Math.min(finalItems.length, 4), value: finalItems.map(function (_a) {
                                    var url = _a.url;
                                    return url;
                                }).join('\n') }, void 0), _jsx(CopyableTextField, { label: "HTML", rows: Math.min(finalItems.length, 4), value: finalItems.map(getHTMLAnchorCode).join('\n') }, void 0)] }), void 0), _jsx("div", __assign({ className: "copy-link-dialog-buttons" }, { children: _jsx(Button, __assign({ variant: "contained", onClick: handleClose }, { children: "Close" }), void 0) }), void 0)] }), void 0)] }, void 0));
};
export default memo(CopyLinkModal);
