export var clamp = function (num, min, max) {
    if (min === void 0) { min = 0; }
    if (max === void 0) { max = min + 1; }
    return Math.min(Math.max(num, min), max);
};
export var toDecimalPlaces = function (num, places) {
    if (places === void 0) { places = 2; }
    return Math.round(num * Math.pow(10, places)) / Math.pow(10, places);
};
export var safeNum = function (num, decimalPlaces) {
    if (isNaN(num) || !isFinite(num)) {
        return 0;
    }
    if (decimalPlaces !== undefined) {
        return toDecimalPlaces(num, decimalPlaces);
    }
    return num;
};
export var padLeft = function (num, width, char) {
    if (width === void 0) { width = 3; }
    if (char === void 0) { char = '0'; }
    return (char.repeat(width) + num).substr(-width);
};
export var delay = function (time) {
    if (time === void 0) { time = 1000; }
    return new Promise(function (res, rej) { return setTimeout(res, time); });
};
export var getThumbOptions = function (input) { return ({
    thumb_x: input.thumb_x,
    thumb_y: input.thumb_y,
    thumb_size: input.thumb_size,
}); };
export var toSlug = function (text) {
    return text
        .toLowerCase()
        .replace(/[^a-z0-9 ]/g, '')
        .replace(/\s/g, '-');
};
export var capitalise = function (text) {
    return text
        .split(/\s/g)
        .filter(function (str) { return str; })
        .map(function (str) { return str.trim().toLowerCase(); })
        .map(function (str) { return str.slice(0, 1).toUpperCase() + str.slice(1); })
        .join(' ');
};
