var authDetails = {
    username: '',
    password: '',
};
// const savedLogin = localStorage.getItem('saved-login');
// if (savedLogin) {
//   authDetails = JSON.parse(savedLogin) as Auth;
// }
var isAuthorisedValue = false;
export var setAuthDetails = function (auth) { return (authDetails = auth); };
export var isAuthorised = function () { return isAuthorisedValue; };
export var setAuthorised = function (value) {
    // if (value === true) {
    //   localStorage.setItem('saved-login', JSON.stringify(authDetails));
    // }
    isAuthorisedValue = value;
};
export var getAuthHeader = function () {
    var username = authDetails.username, password = authDetails.password;
    return {
        Authorization: 'Basic ' + window.btoa(username + ':' + password),
    };
};
