var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Lock as LockOnIcon, LockOpen as LockOffIcon, Visibility as VisibilityIcon, VisibilityOffOutlined as VisibilityOffIcon } from '@material-ui/icons';
import { ALBUM_FLAG_CONFIGS_RAW } from '@common/flagConstants';
import { VaultClosedIcon, VaultOpenIcon } from '@client/components/Icons/Vault';
import { NonVIPIcon, VIPIcon } from '@client/components/Icons/VIP';
import { NonPortfolioIcon, PortfolioIcon } from '@client/components/Icons/Portfolio';
/**
 * IMPORTANT:
 * Remember to also update the flag constants in src/common/flagConstants.ts
 */
export var ALBUM_FLAG_DESCRIPTIONS = {
    enabled: 'Enabled - Is included on main site by default',
    portfolio: "Portfolio - Is included in portfolio listings (note: independent of 'enabled')",
    vip: 'VIP - Added to listings for VIPs (including photos) via Access Tokens',
    blurred: 'Locked - Locked (blurred) on main page & all photos in the album are locked/blurred',
    vaulted: 'Vaulted - Not enabled anywhere (overrides everything else)',
};
var displayConfigs = {
    enabled: {
        label: 'Enabled',
        notLabel: 'Disabled',
        description: ALBUM_FLAG_DESCRIPTIONS.enabled,
        on: VisibilityIcon,
        off: VisibilityOffIcon,
        color: 'green',
    },
    portfolio: {
        label: 'Portfolio',
        notLabel: 'Non-Portfolio',
        description: ALBUM_FLAG_DESCRIPTIONS.portfolio,
        on: PortfolioIcon,
        off: NonPortfolioIcon,
        color: 'cyan',
    },
    vip: {
        label: 'VIP',
        notLabel: 'Non-VIP',
        description: ALBUM_FLAG_DESCRIPTIONS.vip,
        on: VIPIcon,
        off: NonVIPIcon,
        color: 'purple',
    },
    blurred: {
        label: 'Locked',
        notLabel: 'Unlocked',
        description: ALBUM_FLAG_DESCRIPTIONS.blurred,
        on: LockOnIcon,
        off: LockOffIcon,
        color: 'red',
    },
    vaulted: {
        label: 'Vaulted',
        notLabel: 'Unvaulted',
        description: ALBUM_FLAG_DESCRIPTIONS.vaulted,
        on: VaultClosedIcon,
        off: VaultOpenIcon,
        color: 'blue',
    },
};
export var ALBUM_FLAG_CONFIGS = ALBUM_FLAG_CONFIGS_RAW.map(function (rawConfig) { return (__assign(__assign({}, rawConfig), displayConfigs[rawConfig.name])); });
export var ALBUM_FLAG_CONFIG_DICT = ALBUM_FLAG_CONFIGS.reduce(function (dict, config) {
    dict[config.name] = config;
    return dict;
}, {});
