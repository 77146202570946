import React, { useEffect } from 'react';
var PREFIX = 'FINSBURYPUCK-';
function useStickyState(defaultValue, key) {
    var _a = React.useState(function () {
        var stickyValue = window.localStorage.getItem(PREFIX + key);
        return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
    }), value = _a[0], setValue = _a[1];
    useEffect(function () {
        window.localStorage.setItem(PREFIX + key, JSON.stringify(value));
    }, [key, value]);
    return [value, setValue];
}
export default useStickyState;
