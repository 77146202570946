var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { clx } from '@client/utils/styleUtils';
import { FormControlLabel } from '@material-ui/core';
import { CustomInputElement } from '../Form/Input';
import IconCheckbox from '../IconCheckbox/IconCheckbox';
export var IconCheckboxInput = function (_a) {
    var name = _a.name, label = _a.label, type = _a.type, on = _a.on, off = _a.off, className = _a.className, title = _a.title;
    var standardProps = { name: name, label: label, type: type };
    return (_jsx(CustomInputElement, __assign({}, standardProps, { render: function (value, onChange) { return (_jsx("span", __assign({ title: title, className: clx('album-top-btn', !!value && 'album-top-btn-enabled', className) }, { children: _jsx(FormControlLabel, { control: _jsx(IconCheckbox, { checked: value, onChange: onChange, on: on, off: off }, void 0), label: label }, void 0) }), void 0)); } }), void 0));
};
export var AlbumTopLink = function (_a) {
    var config = _a.config;
    return _jsx(IconCheckboxInput, { name: config.name, label: config.label, title: config.description, type: "boolean", on: _jsx(config.on, {}, void 0), off: _jsx(config.off, {}, void 0), className: 'album-top-link-' + config.color }, void 0);
};
