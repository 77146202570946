var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { Button } from '@material-ui/core';
import { Launch as LaunchIcon, Clear as ClearIcon, ExposureNeg1 as Minus1Icon, ExposurePlus1 as Plus1Icon, Today as TodayIcon } from '@material-ui/icons';
import FormContext from '../Form/FormContext';
import { toSlug } from '../../utils/utils';
var DAY = 24 * 60 * 60 * 1000;
var iconButtonStyle = {
    minWidth: 0,
};
var buttonWrapper = function (Component) {
    return function (_a) {
        var show = _a.show, props = __rest(_a, ["show"]);
        var output = useContext(FormContext);
        if (show && !show(output))
            return _jsx(_Fragment, {}, void 0);
        return (_jsx("div", { children: _jsx(Component, __assign({}, props, output), void 0) }, void 0));
    };
};
export var BtnGotoAlbumOnPortfolio = buttonWrapper(function (_a) {
    var form = _a.form;
    return (_jsx(Button, __assign({ onClick: function () { return window.open("https://www.jackiecannon.co.uk/view/".concat(form.name), '_blank').focus(); }, style: iconButtonStyle }, { children: _jsx(LaunchIcon, {}, void 0) }), void 0));
});
export var BtnSlugName = buttonWrapper(function (_a) {
    var form = _a.form, setProperty = _a.setProperty;
    return (_jsx(Button, __assign({ onClick: function () { return setProperty('name', toSlug(form.title)); }, style: { textTransform: 'none', whiteSpace: 'nowrap' } }, { children: toSlug(form.title) }), void 0));
});
export var BtnAutoModelName = buttonWrapper(function (_a) {
    var form = _a.form, setProperty = _a.setProperty;
    return (_jsx(Button, __assign({ onClick: function () { return setProperty('model_name', form.title); }, style: { textTransform: 'none', whiteSpace: 'nowrap' } }, { children: form.title }), void 0));
});
export var BtnClearField = buttonWrapper(function (_a) {
    var propName = _a.propName, setProperty = _a.setProperty;
    return (_jsx(Button, __assign({ onClick: function () { return setProperty(propName, ''); }, style: iconButtonStyle }, { children: _jsx(ClearIcon, {}, void 0) }), void 0));
});
export var BtnPrefill = buttonWrapper(function (_a) {
    var propName = _a.propName, value = _a.value, display = _a.display, title = _a.title, formOutput = __rest(_a, ["propName", "value", "display", "title"]);
    var setProperty = formOutput.setProperty;
    var val = typeof value === 'function' ? value(formOutput) : value;
    return (_jsx(Button, __assign({ onClick: function () { return setProperty(propName, val); }, title: title, style: { textTransform: 'none', whiteSpace: 'nowrap' } }, { children: display }), void 0));
});
export var BtnSetToToday = buttonWrapper(function (_a) {
    var propName = _a.propName, type = _a.type, time = _a.time, offset = _a.offset, display = _a.display, title = _a.title, form = _a.form, setProperty = _a.setProperty;
    var current = new Date(form[propName] || Date.now()).getTime();
    var today = new Date(time || current + (offset || 0)).toISOString();
    return (_jsx(Button, __assign({ onClick: function () { return setProperty(propName, today.substring(0, type === 'datetime-local' ? 16 : 10)); }, style: iconButtonStyle, title: title }, { children: display }), void 0));
});
export var DateBtns = function (_a) {
    var propName = _a.propName, type = _a.type;
    return (_jsxs(_Fragment, { children: [_jsx(BtnSetToToday, { propName: propName, type: type, offset: -DAY, display: _jsx(Minus1Icon, {}, void 0), title: "Minus 1 Day from date" }, void 0), _jsx(BtnSetToToday, { propName: propName, type: type, time: Date.now(), display: _jsx(TodayIcon, {}, void 0), title: "Set to Today" }, void 0), _jsx(BtnSetToToday, { propName: propName, type: type, offset: DAY, display: _jsx(Plus1Icon, {}, void 0), title: "Add 1 Day to date" }, void 0)] }, void 0));
};
