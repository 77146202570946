/**
 * IMPORTANT:
 * Remember to also update the flag constants in src/client/src/constants/flagConstants.ts
 */
export var ALBUM_FLAG_CONFIGS_RAW = [
    {
        name: 'enabled',
        wildcard: '*:enabled-albums',
        notWildcard: '*:disabled-albums',
    },
    {
        name: 'portfolio',
        wildcard: '*:portfolio-albums',
        notWildcard: '*:notportfolio-albums',
    },
    {
        name: 'vip',
        wildcard: '*:vip-albums',
        notWildcard: '*:non-vip-albums',
    },
    {
        name: 'blurred',
        wildcard: '*:locked-albums',
        notWildcard: '*:unlocked-albums',
    },
    {
        name: 'vaulted',
        wildcard: '*:vaulted-albums',
        notWildcard: '*:unvaulted-albums',
    },
];
