var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AdjustType } from './types';
import { clamp, safeNum } from '../../utils/utils';
// Box positions are in the centre
// Thumb positions are top-left corner
export var convertThumbToBox = function (thumb, imageSize) {
    var size = Math.round(thumb.thumb_size * imageSize.width);
    return {
        x: Math.round(thumb.thumb_x * imageSize.width + size / 2),
        y: Math.round(thumb.thumb_y * imageSize.width + size / 2),
        size: size,
    };
};
export var convertBoxToThumb = function (boxOptions, imageSize) {
    var topLeft = getCornerPosition(AdjustType.TOP_LEFT, boxOptions);
    return {
        thumb_x: safeNum(topLeft.x / imageSize.width, 5),
        thumb_y: safeNum(topLeft.y / imageSize.width, 5),
        thumb_size: safeNum(boxOptions.size / imageSize.width, 5),
    };
};
export var areThumbsDifferent = function (a, b) {
    return a.thumb_x !== b.thumb_x || a.thumb_y !== b.thumb_y || a.thumb_size !== b.thumb_size;
};
export var normaliseOptions = function (options, imageSize, prioritiseSize) {
    var newOptions = __assign({}, options);
    if (prioritiseSize) {
        var halfSize = newOptions.size / 2;
        var x = clamp(newOptions.x, halfSize, imageSize.width - halfSize);
        var y = clamp(newOptions.y, halfSize, imageSize.height - halfSize);
        var size = Math.min(newOptions.size, imageSize.width, imageSize.height);
        return __assign(__assign({}, newOptions), { x: x, y: y, size: size });
    }
    else {
        var dU = newOptions.y;
        var dD = imageSize.height - newOptions.y;
        var dL = newOptions.x;
        var dR = imageSize.width - newOptions.x;
        var size = Math.min(newOptions.size, dU * 2, dD * 2, dL * 2, dR * 2, imageSize.width, imageSize.height);
        return __assign(__assign({}, newOptions), { size: size });
    }
};
export var getOppositeAdjustType = function (type) {
    var corners = [
        AdjustType.TOP_LEFT,
        AdjustType.TOP_RIGHT,
        AdjustType.BOTTOM_RIGHT,
        AdjustType.BOTTOM_LEFT,
    ];
    if (corners.includes(type)) {
        return corners[(corners.indexOf(type) + 2) % 4];
    }
    else {
        return type;
    }
};
export var getPolarity = function (type) {
    var _a;
    return ((_a = {},
        _a[AdjustType.TOP_LEFT] = { x: -1, y: -1 },
        _a[AdjustType.TOP_RIGHT] = { x: 1, y: -1 },
        _a[AdjustType.BOTTOM_LEFT] = { x: -1, y: 1 },
        _a[AdjustType.BOTTOM_RIGHT] = { x: 1, y: 1 },
        _a)[type]);
};
export var getCornerPosition = function (corner, options) {
    var x = options.x, y = options.y;
    var polarity = getPolarity(corner);
    return {
        x: x + (options.size / 2) * polarity.x,
        y: y + (options.size / 2) * polarity.y,
    };
};
