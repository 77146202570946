import { ALBUM_FLAG_CONFIGS_RAW } from '@common/flagConstants';
export var WILDCARD = '*';
var isInTokenArray = function (arr, album) {
    if (arr.includes(album.id) || arr.includes(WILDCARD)) {
        return true;
    }
    for (var _i = 0, ALBUM_FLAG_CONFIGS_RAW_1 = ALBUM_FLAG_CONFIGS_RAW; _i < ALBUM_FLAG_CONFIGS_RAW_1.length; _i++) {
        var config = ALBUM_FLAG_CONFIGS_RAW_1[_i];
        if (arr.includes(config.wildcard) && album[config.name]) {
            return true;
        }
        if (arr.includes(config.notWildcard) && !album[config.name]) {
            return true;
        }
    }
    return false;
};
export var isIncluded = function (album, include, exclude) {
    if (isInTokenArray(include, album)) {
        return !isInTokenArray(exclude, album);
    }
    return false;
};
export var isAlbumUnlocked = function (album, token) { return token && isIncluded(album, token.include, token.exclude); };
