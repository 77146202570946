var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { Box, Button, ButtonGroup } from '@material-ui/core';
import { Check as CheckIcon, NotInterested as NotInterestedIcon } from '@material-ui/icons';
/**
 *
 * Example
 *
 * const filtEnabled = useFilterControl('unique-key-a', null);
 * const filtVaulted = useFilterControl('unique-key-b', null);
 *
 * const filtered = items
 *    .filter(filterByControl(filtEnabled, 'enabled'));
 *    .filter(filterByControl(filtVaulted, 'vaulted'));
 *
 * const unifiedItems = [
 *  { state: filtEnabled, label: 'Enabled' },
 *  { state: filtVaulted, label: 'Vaulted', icon: <Icon /> },
 * ]
 *
 * return <>
 *    <UnifiedFilterControl items={filtEnabled} label="Filters" off={<Icon />} />
 *    {filtered.map(item => <></>)}
 * </>
 */
var UnifiedFilterControl = function (_a) {
    var items = _a.items, label = _a.label, off = _a.off;
    var selectedItem = items.find(function (item) { return item.state[0] === true; });
    var onClick = function (clickedItem) { return function () {
        for (var _i = 0, items_1 = items; _i < items_1.length; _i++) {
            var item = items_1[_i];
            var newValue = item === clickedItem || null;
            if (newValue !== item.state[0]) {
                item.state[1](newValue);
            }
        }
    }; };
    var getSelectedStyle = function (item) {
        var chosenColor = (item === null || item === void 0 ? void 0 : item.color) ? item.color : '64, 64, 64';
        return {
            backgroundColor: "rgba(".concat(chosenColor, ", 0.2)"),
            color: "rgba(".concat(chosenColor, ", 1)"),
        };
    };
    return (_jsxs(Box, __assign({ display: "inline-flex", alignItems: "center", margin: " 0.1em 0.75em 0.1em 0" }, { children: [!!label && (_jsx(Box, __assign({ marginRight: "0.5em", whiteSpace: "nowrap" }, { children: label }), void 0)), _jsxs(ButtonGroup, __assign({ size: "small" }, { children: [_jsx(Button, __assign({ onClick: onClick(), title: "Off", style: !selectedItem ? getSelectedStyle() : {} }, { children: off || _jsx(NotInterestedIcon, {}, void 0) }), void 0), items.map(function (item, index) { return (_jsx(Button, __assign({ onClick: onClick(item), title: "Filter".concat(label ? ' by ' + label : ''), style: item.state[0] ? getSelectedStyle(item) : {} }, { children: item.icon || _jsx(CheckIcon, {}, void 0) }), index)); })] }), void 0)] }), void 0));
};
export default memo(UnifiedFilterControl);
