var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useHistory } from 'react-router-dom';
import './styles.css';
import { useAuthCheck } from '../../hooks/useCheckAuth';
import AlbumForm from '../../components/AlbumForm/AlbumForm';
import { getEmptyMstAlbum } from '@common/types/MstData';
import { Typography } from '@material-ui/core';
var NewAlbum = function () {
    var history = useHistory();
    useAuthCheck();
    var initialData = getEmptyMstAlbum();
    var onSubmitted = function (_a) {
        var id = _a.id;
        history.push("/album/".concat(id));
    };
    return (_jsxs("div", { children: [_jsx(Typography, __assign({ variant: "h4" }, { children: "New Album" }), void 0), _jsx(AlbumForm, { initialData: initialData, onSubmitted: onSubmitted }, void 0)] }, void 0));
};
export default NewAlbum;
