var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import './styles.css';
import { useAuthCheck } from '../../hooks/useCheckAuth';
import AlbumForm from '../../components/AlbumForm/AlbumForm';
import DataContext from '../../context/DataContext';
import { Typography } from '@material-ui/core';
var Album = function () {
    useAuthCheck();
    var params = useParams();
    var _a = useContext(DataContext), data = _a.data, getAlbum = _a.getAlbum;
    var _b = useState(getAlbum(params.albumId)), initialData = _b[0], setInitialData = _b[1];
    useEffect(function () {
        setInitialData(getAlbum(params.albumId));
    }, [getAlbum, params]);
    var titleSuffix = initialData && initialData.name ? " (".concat(initialData.name, ")") : '';
    var prev, next;
    if (initialData) {
        var index = data.albums.indexOf(initialData);
        prev = [
            data.albums[(data.albums.length + index + 4) % data.albums.length],
            data.albums[(data.albums.length + index + 3) % data.albums.length],
            data.albums[(data.albums.length + index + 2) % data.albums.length],
            data.albums[(data.albums.length + index + 1) % data.albums.length],
        ];
        next = [
            data.albums[(data.albums.length + index - 1) % data.albums.length],
            data.albums[(data.albums.length + index - 2) % data.albums.length],
            data.albums[(data.albums.length + index - 3) % data.albums.length],
            data.albums[(data.albums.length + index - 4) % data.albums.length],
        ];
    }
    return (_jsxs("div", { children: [_jsxs(Typography, __assign({ variant: "h4" }, { children: ["Edit Album", titleSuffix] }), void 0), initialData ? _jsx(AlbumForm, { initialData: initialData, prev: prev, next: next }, void 0) : _jsx("div", { children: "Loading..." }, void 0)] }, void 0));
};
export default Album;
