var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo } from 'react';
import { Box, Button, IconButton, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { ArrowDownward as ArrowDownIcon, ArrowUpward as ArrowUpIcon, Visibility as VisibilityIcon, VisibilityOffOutlined as VisibilityOffIcon } from '@material-ui/icons';
import { clx } from '@client/utils/styleUtils';
import IconCheckbox from '../IconCheckbox/IconCheckbox';
import RemoveModal from '../AlbumForm/RemoveModal';
import './styles.css';
import FilterControl, { filterByControl, useFilterControl } from '../FilterControl/FilterControl';
var ReorderList = function (_a) {
    var stickyKey = _a.stickyKey, originalItems = _a.items, showEnabled = _a.showEnabled, showMove = _a.showMove, showRemove = _a.showRemove, showAdd = _a.showAdd, showEnabledFilter = _a.showEnabledFilter, extraFilters = _a.extraFilters, onChange = _a.onChange, getEmptyItem = _a.getEmptyItem, itemBtns = _a.itemBtns, itemFields = _a.itemFields;
    var filtEnabled = useFilterControl(stickyKey + '-enabled', null);
    var items = JSON.parse(JSON.stringify(originalItems));
    var filteredItems = items;
    filteredItems = items.filter(filterByControl(filtEnabled, 'enabled'));
    var filterStates = [];
    var _loop_1 = function (index) {
        var filter = extraFilters[index];
        // eslint-disable-next-line react-hooks/rules-of-hooks
        filterStates[index] = useFilterControl(stickyKey + '-' + filter.label.replace(' ', ''), null);
        filteredItems = filteredItems.filter(filterByControl(filterStates[index], function (item, actualValue) { return filter.getValue(item) === actualValue; }));
    };
    for (var index in extraFilters) {
        _loop_1(index);
    }
    if (showEnabled === undefined)
        showEnabled = true;
    if (showMove === undefined)
        showMove = true;
    if (showRemove === undefined)
        showRemove = true;
    if (showAdd === undefined)
        showAdd = true;
    if (showEnabledFilter === undefined)
        showEnabledFilter = true;
    var addButtons = [];
    if (getEmptyItem) {
        addButtons = typeof getEmptyItem === 'function' ? [{ label: 'Add item', get: getEmptyItem }] : getEmptyItem;
    }
    var onValueChange = function (item, name) { return function (e) {
        item[name] = e.target.value;
        onChange(items);
    }; };
    var setValues = function (item) {
        var values = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            values[_i - 1] = arguments[_i];
        }
        values.forEach(function (_a) {
            var key = _a[0], value = _a[1];
            item[key] = value;
        });
        onChange(items);
    };
    var toggleEnabled = function (item) { return function () {
        item.enabled = !item.enabled;
        onChange(items);
    }; };
    var addItem = function (getItem) { return function () { return onChange(__spreadArray(__spreadArray([], items, true), [getItem()], false)); }; };
    var removeItem = function (item) { return function () {
        var index = items.indexOf(item);
        items.splice(index, 1);
        onChange(items);
    }; };
    var moveItem = function (item, dir) { return function () {
        var index = items.indexOf(item);
        var destinationIndex = items.indexOf(filteredItems[(filteredItems.length + filteredItems.indexOf(item) + dir) % filteredItems.length]);
        items.splice(destinationIndex, 0, items.splice(index, 1)[0]);
        onChange(items);
    }; };
    return (_jsxs(_Fragment, { children: [_jsx(Box, __assign({ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "0.5em 0.5em 0" }, { children: _jsxs(Box, { children: [showEnabledFilter && _jsx(FilterControl, { state: filtEnabled, label: "Enabled", color: "var(--green-rgb)", on: _jsx(VisibilityIcon, {}, void 0), off: _jsx(VisibilityOffIcon, {}, void 0), conditionalColor: true }, void 0), extraFilters && extraFilters.map(function (_a, index) {
                            var getValue = _a.getValue, controlProps = __rest(_a, ["getValue"]);
                            return _jsx(FilterControl, __assign({ state: filterStates[index] }, controlProps), controlProps.label + index);
                        })] }, void 0) }), void 0), _jsx(List, __assign({ className: "reorder-list" }, { children: filteredItems.map(function (item, index) { return (_jsxs(ListItem, __assign({ className: clx('reorder-li', showEnabled && !item.enabled ? 'list-item-disabled' : '') }, { children: [_jsxs(ListItemIcon, __assign({ className: "reorder-icons" }, { children: [showEnabled && (_jsx(IconCheckbox, { checked: item.enabled, onChange: toggleEnabled(item), color: "secondary", conditionalColor: true, edge: "start", on: _jsx(VisibilityIcon, {}, void 0), off: _jsx(VisibilityOffIcon, {}, void 0), disabled: item.frozen }, void 0)), showMove && (_jsxs(_Fragment, { children: [_jsx(IconButton, __assign({ edge: "start", onClick: moveItem(item, 1), disabled: items.indexOf(item) === items.length - 1 }, { children: _jsx(ArrowDownIcon, {}, void 0) }), void 0), _jsx(IconButton, __assign({ edge: "start", onClick: moveItem(item, -1), disabled: items.indexOf(item) === 0 }, { children: _jsx(ArrowUpIcon, {}, void 0) }), void 0)] }, void 0)), showRemove && _jsx(RemoveModal, { edge: "start", name: "this item", onApprove: removeItem(item), disabled: item.frozen }, void 0), itemBtns && itemBtns(item, index, onValueChange, setValues)] }), void 0), _jsx(ListItemText, { children: _jsx(Box, __assign({ display: "flex", className: "item-box" }, { children: itemFields(item, index, onValueChange, setValues) }), void 0) }, void 0)] }), item.id)); }) }), void 0), showAdd && (_jsx(_Fragment, { children: _jsx(Box, __assign({ display: "flex", width: "100%", margin: " 0 0.5em 0.5em" }, { children: addButtons &&
                        addButtons.map(function (addBtn) { return (_jsx(ListItemText, { primary: _jsx(Button, __assign({ onClick: addItem(addBtn.get), style: { width: '100%' } }, { children: addBtn.label }), void 0) }, addBtn.label)); }) }), void 0) }, void 0))] }, void 0));
};
export default memo(ReorderList);
