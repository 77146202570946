var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { SvgIcon } from '@material-ui/core';
export var PortfolioIcon = function (props) {
    return (_jsx(SvgIcon, __assign({}, props, { viewBox: "0, 0, 24, 24" }, { children: _jsx("path", { d: "M12,0.531 C5.669,0.531 0.531,5.669 0.531,12 C0.531,18.331 5.669,23.469 12,23.469 C18.331,23.469 23.469,18.331 23.469,12 C23.469,5.669 18.331,0.531 12,0.531 z M12,3.972 C13.904,3.972 15.441,5.509 15.441,7.412 C15.441,9.316 13.904,10.853 12,10.853 C10.096,10.853 8.559,9.316 8.559,7.412 C8.559,5.509 10.096,3.972 12,3.972 z M12,20.257 C9.133,20.257 6.598,18.789 5.119,16.565 C5.153,14.282 9.706,13.032 12,13.032 C14.282,13.032 18.847,14.282 18.881,16.565 C17.402,18.789 14.867,20.257 12,20.257 z" }, void 0) }), void 0));
};
export var NonPortfolioIcon = function (props) {
    return (_jsxs(SvgIcon, __assign({}, props, { viewBox: "0, 0, 24, 24" }, { children: [_jsx("path", { d: "M12,0.531 C18.334,0.531 23.469,5.666 23.469,12 C23.469,18.334 18.334,23.469 12,23.469 L12,23.469 C5.666,23.469 0.531,18.334 0.531,12 C0.531,5.666 5.666,0.531 12,0.531 z M12,2.594 C6.805,2.594 2.594,6.805 2.594,12 C2.594,17.195 6.805,21.406 12,21.406 L12,21.406 C17.195,21.406 21.406,17.195 21.406,12 C21.406,6.805 17.195,2.594 12,2.594 z" }, void 0), _jsx("path", { d: "M18.579,3.653 L20.347,5.421 L5.449,20.319 L3.681,18.551 L18.579,3.653 z" }, void 0), _jsx("path", { d: "M13.31,13.165 C15.335,13.491 17.371,14.026 18.669,15.758 C18.836,15.98 18.811,16.295 18.881,16.565 C17.402,18.789 14.867,20.257 12,20.257 C10.231,20.297 9.05,19.729 7.561,18.914 L13.31,13.165 z" }, void 0), _jsx("path", { d: "M7.356,14.169 L5.175,16.35 C5.291,15.205 6.393,14.576 7.356,14.169 z" }, void 0), _jsx("path", { d: "M12,3.972 C13.663,4.046 14.585,4.848 15.204,6.321 L10.908,10.616 C9.401,10.157 8.709,8.902 8.559,7.412 C8.559,5.509 10.096,3.972 12,3.972 L12,3.972 z" }, void 0)] }), void 0));
};
