var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { Box, Grid } from '@material-ui/core';
import { ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import Form from '../../components/Form/Form';
import { fetchPath, optionsPOST as POST, headersJSON as hJSON } from '../../services/fetch';
import DataContext from '../../context/DataContext';
import Input from '../Form/Input';
import PhotoList from './PhotoList';
import { BtnAutoModelName, BtnClearField, BtnGotoAlbumOnPortfolio, BtnPrefill, BtnSlugName, DateBtns } from './AlbumFormButtons';
import { AlbumTopLink } from './TopLink';
import { ALBUM_FLAG_CONFIGS } from '@client/constants/flagConstants';
import './styles.css';
var AlbumForm = function (_a) {
    var iData = _a.initialData, onSubmitted = _a.onSubmitted, prev = _a.prev, next = _a.next;
    var updateData = useContext(DataContext).update;
    var onSubmit = function (form) { return __awaiter(void 0, void 0, void 0, function () {
        var formStr, response, json;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    formStr = JSON.stringify(form);
                    return [4 /*yield*/, fetchPath('api/album', POST(formStr, hJSON))];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    json = _a.sent();
                    return [4 /*yield*/, updateData()];
                case 3:
                    _a.sent();
                    onSubmitted && onSubmitted(json);
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(Form, __assign({ data: iData, onSubmit: onSubmit, className: "album-form", stickyFooter: true }, { children: [_jsx(Grid, __assign({ container: true, spacing: 3 }, { children: _jsxs(Grid, __assign({ item: true, xs: 12, className: "album-top-btns-container" }, { children: [_jsxs(Box, __assign({ display: "flex", width: "100%", className: "album-top-btns", marginBottom: "2px" }, { children: [prev && (_jsx("span", __assign({ className: "album-top-btn album-top-link album-top-link-arrow" }, { children: _jsx("div", __assign({ className: "album-top-link-icon" }, { children: _jsx(ArrowBackIcon, {}, void 0) }), void 0) }), void 0)), prev &&
                                    prev.slice(-3).map(function (item) { return (_jsx("span", __assign({ className: "album-top-btn album-top-link album-top-link-prev" }, { children: _jsx(Link, __assign({ to: "/album/".concat(item.id) }, { children: _jsx("div", __assign({ className: "album-top-link-text" }, { children: _jsx("div", { children: item.title }, void 0) }), void 0) }), void 0) }), void 0)); }), _jsx("span", __assign({ className: "album-top-btn album-top-link album-top-link-current" }, { children: _jsx("div", __assign({ className: "album-top-link-text" }, { children: _jsx("div", { children: iData.title }, void 0) }), void 0) }), void 0), next &&
                                    next.slice(0, 3).map(function (item) { return (_jsx("span", __assign({ className: "album-top-btn album-top-link album-top-link-next" }, { children: _jsx(Link, __assign({ to: "/album/".concat(item.id) }, { children: _jsx("div", __assign({ className: "album-top-link-text" }, { children: _jsx("div", { children: item.title }, void 0) }), void 0) }), void 0) }), void 0)); }), next && (_jsx("span", __assign({ className: "album-top-btn album-top-link album-top-link-arrow" }, { children: _jsx("div", __assign({ className: "album-top-link-icon" }, { children: _jsx(ArrowForwardIcon, {}, void 0) }), void 0) }), void 0))] }), void 0), _jsx(Box, __assign({ display: "flex", width: "100%", className: "album-top-btns" }, { children: ALBUM_FLAG_CONFIGS.map(function (config) { return (_jsx(AlbumTopLink, { config: config }, config.name)); }) }), void 0)] }), void 0) }), void 0), _jsxs(Grid, __assign({ container: true, spacing: 3 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, md: 6 }, { children: _jsx(Input, { name: "title", label: "Title", type: "text" }, void 0) }), void 0), _jsxs(Grid, __assign({ item: true, xs: 12, md: 6, className: "with-button" }, { children: [_jsx(Input, { name: "name", label: "Slug Name", type: "text" }, void 0), _jsx(BtnSlugName, { show: function (_a) {
                                    var form = _a.form;
                                    return !form.name && form.title;
                                } }, void 0), _jsx(BtnGotoAlbumOnPortfolio, {}, void 0)] }), void 0), _jsxs(Grid, __assign({ item: true, xs: 12, className: "with-button" }, { children: [_jsx(Input, { name: "description", label: "Description", type: "text" }, void 0), _jsx(BtnClearField, { propName: "description" }, void 0)] }), void 0)] }), void 0), _jsxs(Grid, __assign({ container: true, spacing: 3 }, { children: [_jsxs(Grid, __assign({ item: true, xs: 12, md: 6, className: "with-button" }, { children: [_jsx(Input, { name: "shootDate", label: "Shoot Date", type: "date" }, void 0), _jsx(DateBtns, { propName: "shootDate", type: "date" }, void 0)] }), void 0), _jsxs(Grid, __assign({ item: true, xs: 12, md: 6, className: "with-button" }, { children: [_jsx(Input, { name: "postDate", label: "Post Date", type: "date" }, void 0), _jsx(BtnPrefill, { propName: "postDate", value: function (_a) {
                                    var form = _a.form;
                                    return form.shootDate;
                                }, display: "Shoot Date", title: "Set to same as Shoot Date" }, void 0), _jsx(DateBtns, { propName: "postDate", type: "date" }, void 0)] }), void 0)] }), void 0), _jsxs(Grid, __assign({ container: true, spacing: 3 }, { children: [_jsxs(Grid, __assign({ item: true, xs: 12, sm: 6, md: 4, className: "with-button" }, { children: [_jsx(Input, { name: "model_name", label: "Model Name", type: "text" }, void 0), _jsx(BtnAutoModelName, { show: function (_a) {
                                    var form = _a.form;
                                    return !form.model_name && form.title;
                                } }, void 0)] }), void 0), _jsx(Grid, __assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsx(Input, { name: "model_instagram", label: "Model Instagram", type: "text" }, void 0) }), void 0), _jsx(Grid, __assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsx(Input, { name: "model_email", label: "Model Email", type: "text" }, void 0) }), void 0), _jsx(Grid, __assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsx(Input, { name: "model_website", label: "Model Website", type: "text" }, void 0) }), void 0), _jsx(Grid, __assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsx(Input, { name: "model_website_name", label: "Model Website Name", type: "text" }, void 0) }), void 0)] }), void 0), _jsx(PhotoList, {}, void 0)] }), void 0));
};
export default AlbumForm;
