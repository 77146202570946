var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { memo } from 'react';
import { IconButton } from '@material-ui/core';
import { Launch as LaunchIcon } from '@material-ui/icons';
var OpenLinkBtn = function (_a) {
    var url = _a.url, title = _a.title;
    return (_jsx("span", __assign({ title: title || 'Open link' }, { children: _jsx(IconButton, __assign({ edge: "start", onClick: function () { return window.open(url, '_blank').focus(); } }, { children: _jsx(LaunchIcon, {}, void 0) }), void 0) }), void 0));
};
export default memo(OpenLinkBtn);
