var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useAuthCheck } from '../../hooks/useCheckAuth';
import { Typography } from '@material-ui/core';
import { CopyableTextField } from '@client/components/CopyLinkModal/CopyableTextField';
var links = [
    { label: 'Home', text: 'Jackie Cannon', url: 'https://www.jackiecannon.co.uk' },
    { label: 'Directions', url: 'https://www.jackiecannon.co.uk/directions' },
    { label: 'Patreon Log-in', text: 'Log In', url: 'https://www.jackiecannon.co.uk/login' },
    { label: 'About', url: 'https://www.jackiecannon.co.uk/about' },
    { label: 'Contact', url: 'https://www.jackiecannon.co.uk/contact' },
    {},
    { label: 'Instagram', url: 'https://www.instagram.com/_jackiecannon/' },
    { label: 'Personal Instagram', url: 'https://www.instagram.com/_jackcannon/' },
    { label: 'Patreon', url: 'https://www.patreon.com/jackiecannon' },
    { label: 'Twitter', url: 'https://twitter.com/_jackiecannon' },
    { label: 'Purpleport', url: 'https://purpleport.com/portfolio/jackiec' },
];
var getHTMLAnchorCode = function (_a) {
    var url = _a.url, label = _a.label, text = _a.text;
    return "<a href=\"".concat(url, "\">").concat(text || label || url, "</a>");
};
var CopyLinks = function () {
    useAuthCheck();
    return (_jsxs("div", { children: [_jsx(Typography, __assign({ variant: "h4" }, { children: "Copy Links" }), void 0), _jsx("div", __assign({ style: { marginTop: '0.75em' } }, { children: links.map(function (link, index) { return (_jsxs("div", __assign({ style: { marginBottom: '1em', minHeight: '1em' } }, { children: [link.label && _jsx("div", __assign({ style: { fontSize: '1.2em', marginBottom: '0.2em' } }, { children: link.label }), void 0), link.url && (_jsxs("div", __assign({ style: { display: 'flex', flexFlow: 'row nowrap', gap: '1em' } }, { children: [_jsx(CopyableTextField, { value: link.url, opacity: 0.9 }, void 0), _jsx(CopyableTextField, { value: getHTMLAnchorCode(link), opacity: 0.9 }, void 0)] }), void 0))] }), index)); }) }), void 0)] }, void 0));
};
export default CopyLinks;
