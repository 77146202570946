var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { Dialog, IconButton, ListItem, ListItemText, ListItemSecondaryAction, List, Box } from '@material-ui/core';
import { Crop as CropIcon, Close as CloseIcon, ArrowLeft as ArrowLeftIcon, ArrowRight as ArrowRightIcon } from '@material-ui/icons';
import FormContext from '../Form/FormContext';
import ThumbBox from '../ThumbBox/ThumbBox';
import { cloneMstDataPhotos } from '@common/types/MstData';
import { getFilesUrl } from '@common/constants';
import { getThumbOptions, padLeft } from '../../utils/utils';
import './cropModalStyles.css';
var CropModal = function (_a) {
    var photo = _a.photo, photoIndex = _a.photoIndex, buttonClassName = _a.buttonClassName;
    var _b = useContext(FormContext), form = _b.form, setProperty = _b.setProperty;
    var _c = React.useState(false), open = _c[0], setOpen = _c[1];
    var _d = React.useState(photoIndex), chosenPhotoIndex = _d[0], setChosenPhotoIndex = _d[1];
    var chosenPhoto = photo || form.photos[chosenPhotoIndex];
    var thumbOptions = getThumbOptions(chosenPhoto);
    var handleOpen = function () { return setOpen(true); };
    var handleClose = function () {
        setOpen(false);
        setChosenPhotoIndex(photoIndex);
    };
    var navigate = function (dir) { return setChosenPhotoIndex((chosenPhotoIndex + form.photos.length + dir) % form.photos.length); };
    var onSubmit = function (thumbOptions, gotoNext) { return __awaiter(void 0, void 0, void 0, function () {
        var photos, index, newPhoto;
        return __generator(this, function (_a) {
            photos = cloneMstDataPhotos(form.photos);
            index = photos.findIndex(function (_a) {
                var id = _a.id;
                return id === chosenPhoto.id;
            });
            newPhoto = __assign(__assign({}, photos[index]), thumbOptions);
            photos.splice(index, 1, newPhoto);
            setProperty('photos', photos);
            if (gotoNext) {
                navigate(1);
            }
            else {
                handleClose();
            }
            return [2 /*return*/];
        });
    }); };
    var setButtons = [
        {
            text: 'Set & Close',
            value: false,
        },
        {
            text: 'Set & Next',
            value: true,
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(IconButton, __assign({ edge: "end", className: buttonClassName, onClick: handleOpen }, { children: _jsx(CropIcon, {}, void 0) }), void 0), _jsxs(Dialog, __assign({ open: open, onClose: handleClose, maxWidth: "md" }, { children: [_jsx(List, { children: _jsxs(ListItem, { children: [_jsx(ListItemText, { children: "Thumbnail Editor" }, void 0), _jsx(ListItemSecondaryAction, { children: _jsx(IconButton, __assign({ edge: "end", onClick: handleClose }, { children: _jsx(CloseIcon, {}, void 0) }), void 0) }, void 0)] }, void 0) }, void 0), _jsxs("div", __assign({ className: "crop-modal" }, { children: [_jsx(ThumbBox, { url: getFilesUrl() + chosenPhoto.src_full, thumbOptions: thumbOptions, setThumbOptions: onSubmit, setButtons: setButtons }, void 0), !photo && (_jsxs(Box, { children: [_jsx(IconButton, __assign({ onClick: function () { return navigate(-1); } }, { children: _jsx(ArrowLeftIcon, {}, void 0) }), void 0), _jsxs(Box, __assign({ display: "inline-block" }, { children: [padLeft(chosenPhotoIndex + 1, 2, '0'), " / ", padLeft(form.photos.length, 2, '0')] }), void 0), _jsx(IconButton, __assign({ onClick: function () { return navigate(1); } }, { children: _jsx(ArrowRightIcon, {}, void 0) }), void 0)] }, void 0))] }), void 0)] }), void 0)] }, void 0));
};
export default CropModal;
