var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useEffect, useContext } from 'react';
var initialState = {};
var initialOutput = {
    form: initialState,
    setForm: function (newForm) { },
    setProperty: function (name, value) { },
    setProperties: function (newProps) { },
};
var FormContext = React.createContext(initialOutput);
var Provider = FormContext.Provider, FormConsumer = FormContext.Consumer;
var FormProvider = function (_a) {
    var children = _a.children, initialData = _a.initialData;
    var _b = useState(initialData), form = _b[0], setForm = _b[1];
    useEffect(function () {
        setForm(initialData);
    }, [initialData]);
    var setProperty = function (name, value) {
        var newForm = __assign({}, form);
        newForm[name] = value;
        setForm(newForm);
    };
    var setProperties = function (newProps) {
        setForm(__assign(__assign({}, form), newProps));
    };
    var output = {
        form: form,
        setForm: setForm,
        setProperty: setProperty,
        setProperties: setProperties,
    };
    return _jsx(Provider, __assign({ value: output }, { children: children }), void 0);
};
var withFormProvider = function (Component) {
    return function (_a) {
        var children = _a.children, props = __rest(_a, ["children"]);
        var data = props.data;
        return (_jsx(FormProvider, __assign({ initialData: data }, { children: _jsx(Component, __assign({}, props, { children: children }), void 0) }), void 0));
    };
};
var FormAccessor = function (_a) {
    var get = _a.get;
    var output = useContext(FormContext);
    return _jsx(_Fragment, { children: get(output) }, void 0);
};
export default FormContext;
export { FormProvider, FormConsumer, withFormProvider, FormAccessor };
