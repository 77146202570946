var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { SvgIcon } from '@material-ui/core';
export var VIPIcon = function (props) {
    return (_jsx(SvgIcon, __assign({}, props, { viewBox: "0, 0, 283.465, 283.465" }, { children: _jsx("path", { d: "M141.732,7.087 C216.095,7.087 276.378,67.37 276.378,141.732 C276.378,216.095 216.095,276.378 141.732,276.378 C67.37,276.378 7.087,216.095 7.087,141.732 C7.087,67.37 67.37,7.087 141.732,7.087 z M153.259,30.362 Q134.71,30.362 120.004,35.661 Q105.298,40.961 95.096,50.235 Q84.895,59.509 79.463,72.361 Q74.031,85.212 74.031,100.315 Q74.031,117.804 81.715,131.848 L59.987,131.848 L59.987,157.02 L99.469,157.02 Q105.033,167.089 105.033,176.893 Q105.033,180.868 102.383,186.962 Q95.759,186.962 89.135,189.347 Q82.51,191.732 76.416,197.164 Q70.321,202.596 65.154,211.605 Q59.987,220.614 56.808,233.863 L91.254,233.863 Q94.699,224.059 108.213,224.059 Q114.837,224.059 124.376,226.311 Q133.915,228.564 143.72,231.081 Q153.524,233.598 162.533,235.85 Q171.542,238.103 177.106,238.103 Q199.364,238.103 212.348,224.456 Q225.332,210.81 226.657,186.962 L194.595,186.962 Q194.595,193.057 191.018,196.502 Q187.441,199.946 181.346,199.946 Q176.577,199.946 167.832,197.826 Q159.088,195.707 146.104,191.997 L138.95,189.877 Q140.275,185.373 140.937,181.398 Q141.6,177.423 141.6,173.714 Q141.6,170.004 141.202,165.897 Q140.805,161.79 140.275,157.02 L177.636,157.02 L177.636,131.848 L132.856,131.848 Q128.616,122.573 126.364,115.552 Q124.111,108.53 124.111,99.521 Q124.111,87.332 131.001,79.25 Q137.89,71.168 149.019,71.168 Q154.319,71.168 158.823,73.288 Q163.328,75.408 166.64,78.852 Q169.952,82.297 171.807,86.802 Q173.662,91.306 173.662,96.076 Q173.662,97.666 173.264,100.05 Q172.867,102.435 172.337,105.615 L224.802,105.615 Q224.802,66.664 207.446,48.513 Q190.09,30.362 153.259,30.362 z" }, void 0) }), void 0));
};
export var NonVIPIcon = function (props) {
    return (_jsxs(SvgIcon, __assign({}, props, { viewBox: "0, 0, 283.465, 283.465" }, { children: [_jsx("path", { d: "M256.116,70.775 C269.989,91.754 275.75,116.903 276.378,141.732 C276.378,216.095 216.095,276.378 141.732,276.378 C117.576,276.053 91.017,269.79 70.775,256.116 L102.197,224.694 C104.152,224.148 106.197,224.106 108.213,224.059 Q114.837,224.059 124.376,226.311 Q133.915,228.564 143.72,231.081 Q153.524,233.598 162.533,235.85 Q171.542,238.103 177.106,238.103 Q199.364,238.103 212.348,224.456 Q225.332,210.81 226.657,186.962 L194.595,186.962 Q194.595,193.057 191.018,196.502 Q187.441,199.946 181.346,199.946 Q176.577,199.946 167.832,197.826 Q159.088,195.707 146.104,191.997 L138.95,189.877 C139.612,187.512 139.396,188.406 139.674,187.216 L169.871,157.02 L177.636,157.02 L177.636,149.255 L221.276,105.615 L224.802,105.615 L224.753,102.138 L256.116,70.775 z" }, void 0), _jsx("path", { d: "M148.661,7.262 C171.631,8.895 194.621,15.144 213.883,28.155 L199.906,42.132 C186.103,32.813 169.55,30.617 153.259,30.362 Q134.71,30.362 120.004,35.661 Q105.298,40.961 95.096,50.235 Q84.895,59.509 79.463,72.361 Q74.031,85.212 74.031,100.315 Q74.031,117.804 81.715,131.848 L59.987,131.848 L59.987,157.02 L85.018,157.02 L28.155,213.883 C13.741,192.523 7.73,167.186 7.087,141.732 C7.087,67.37 67.37,7.087 141.732,7.087 L148.661,7.262 z" }, void 0), _jsx("path", { d: "M149.019,71.168 Q154.319,71.168 158.823,73.288 C162.399,75.164 160.333,73.955 164.86,77.178 L126.394,115.644 L126.364,115.551 Q124.111,108.53 124.111,99.521 Q124.111,87.332 131.001,79.25 Q137.89,71.168 149.019,71.168 z" }, void 0), _jsx("path", { d: "M239.577,27.21 C245.936,32.604 251.85,38.551 257.159,44.983 L44.983,257.159 C38.613,251.804 32.516,246.006 27.21,239.577 L239.577,27.21 z" }, void 0)] }), void 0));
};
