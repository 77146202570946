var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { SvgIcon } from '@material-ui/core';
export var PatreonIcon = function (props) {
    return (_jsx(SvgIcon, __assign({}, props, { viewBox: "0, 0, 800, 800" }, { children: _jsx("path", { d: "M400,0 C620.914,0 800,179.086 800,400 C800,620.914 620.914,800 400,800 C179.086,800 -0,620.914 -0,400 C-0,179.086 179.086,0 400,0 z M248.409,127.14 L150,127.14 L150,672.86 L248.409,672.86 L248.409,127.14 z M508.847,127.141 C397.712,127.141 307.295,219.055 307.295,332.037 C307.295,444.677 397.712,536.319 508.847,536.318 C619.638,536.319 709.771,444.677 709.771,332.037 C709.771,219.055 619.638,127.141 508.847,127.141 z" }, void 0) }), void 0));
};
export var NoPatreonIcon = function (props) {
    return (_jsxs(SvgIcon, __assign({}, props, { viewBox: "0, 0, 800, 800" }, { children: [_jsx("path", { d: "M744.555,196.866 C781.726,257.898 799.101,328.969 800,400 C800,620.914 620.914,800 400,800 C329.117,799.587 257.696,781.515 196.866,744.555 L424.096,517.325 C450.439,530.193 479.672,535.943 508.846,536.318 C619.638,536.319 709.771,444.677 709.771,332.037 C709.982,302.946 703.579,275.677 692.474,248.947 L744.555,196.866 z" }, void 0), _jsx("path", { d: "M410.324,0.131 C477.813,2.447 545.162,20.222 603.134,55.445 L530.222,128.357 C523.136,127.417 515.984,127.234 508.846,127.141 C397.712,127.141 307.295,219.055 307.295,332.037 C307.295,338.175 307.541,344.316 308.154,350.424 L248.409,410.17 L248.409,127.14 L150,127.14 L150,508.579 L55.445,603.134 C18.274,542.102 0.899,471.031 -0,400 C-0,179.086 179.086,0 400,0 L410.324,0.131 z" }, void 0), _jsx("path", { d: "M704.519,24.77 C730.496,45.801 754.161,69.57 775.12,95.59 L95.025,775.686 C68.735,754.977 45.57,730.732 24.314,704.975 L704.519,24.77 z" }, void 0)] }), void 0));
};
