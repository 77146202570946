export var getPhotoID = function (album, photo) { return ({ album: album.id, photo: photo.id }); };
export var getPhotoObj = function (albums, albumID, photoID) {
    var album = albums.find(function (_a) {
        var id = _a.id;
        return id === albumID;
    });
    return album ? album.photos.find(function (_a) {
        var id = _a.id;
        return id === photoID;
    }) : undefined;
};
export var getPhotoThumb = function (albums, albumID, photoID) {
    var photo = getPhotoObj(albums, albumID, photoID);
    return photo === null || photo === void 0 ? void 0 : photo.src_thumb;
};
