var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo } from 'react';
import { Dialog, IconButton, ListItem, ListItemText, ListItemSecondaryAction, List, Button } from '@material-ui/core';
import { Close as CloseIcon, Delete as DeleteIcon } from '@material-ui/icons';
import './removeModalStyles.css';
var RemoveModal = function (_a) {
    var name = _a.name, type = _a.type, edge = _a.edge, buttonClassName = _a.buttonClassName, disabled = _a.disabled, onApprove = _a.onApprove;
    var _b = React.useState(false), open = _b[0], setOpen = _b[1];
    var buttonType = type || 'icon';
    var handleOpen = function () { return setOpen(true); };
    var handleClose = function () { return setOpen(false); };
    var handleApprove = function () {
        onApprove();
        handleClose();
    };
    return (_jsxs(_Fragment, { children: [buttonType === 'icon' && (_jsx(IconButton, __assign({ edge: edge, className: buttonClassName, onClick: handleOpen, disabled: disabled }, { children: _jsx(DeleteIcon, {}, void 0) }), void 0)), buttonType === 'text' && (_jsxs(Button, __assign({ onClick: handleOpen, style: { minWidth: 0, padding: 6, backgroundColor: 'var(--red)', color: 'white' }, disabled: disabled }, { children: [_jsx(DeleteIcon, {}, void 0), " Remove ", name] }), void 0)), _jsxs(Dialog, __assign({ open: open, onClose: handleClose, maxWidth: "md" }, { children: [_jsx(List, { children: _jsxs(ListItem, { children: [_jsxs(ListItemText, { children: ["Are you sure you want to remove ", name, "?"] }, void 0), _jsx(ListItemSecondaryAction, { children: _jsx(IconButton, __assign({ edge: "end", onClick: handleClose }, { children: _jsx(CloseIcon, {}, void 0) }), void 0) }, void 0)] }, void 0) }, void 0), _jsxs("div", __assign({ className: "dialog-buttons" }, { children: [_jsx(Button, __assign({ variant: "contained", onClick: handleClose }, { children: "Cancel" }), void 0), _jsxs(Button, __assign({ variant: "contained", color: "primary", onClick: handleApprove }, { children: ["Remove ", name] }), void 0)] }), void 0)] }), void 0)] }, void 0));
};
export default memo(RemoveModal);
