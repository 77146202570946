var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { ALBUM_FLAG_CONFIGS } from '@client/constants/flagConstants';
import { Box } from '@material-ui/core';
export var FlagIcon = function (_a) {
    var config = _a.config, album = _a.album;
    return (_jsx("span", __assign({ title: config.description, style: { display: 'flex', alignItems: 'center' } }, { children: album[config.name] ? _jsx(config.on, { style: { fontSize: '1.5em', color: "var(--".concat(config.color, ")") } }, void 0) : _jsx(config.off, { style: { fontSize: '1.5em', color: 'rgba(255, 255, 255, 0.4)' } }, void 0) }), void 0));
};
export var FlagIcons = function (_a) {
    var album = _a.album;
    return (_jsx(Box, __assign({ display: "flex", width: "7.8em", justifyContent: "space-between", margin: "0 0.4em 0 0", whiteSpace: "nowrap", fontSize: "0.85em", gridGap: "0.1em" }, { children: ALBUM_FLAG_CONFIGS.map(function (config) { return (_jsx(FlagIcon, { config: config, album: album }, config.name)); }) }), void 0));
};
