var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import './submitButtonStyles.css';
import { SentimentVeryDissatisfiedOutlined as SadFaceIcon, ThumbUpAltOutlined as ThumbsUpIcon } from '@material-ui/icons';
import { delay } from '../../utils/utils';
import { clx } from '@client/utils/styleUtils';
var SubmitButton = function (_a) {
    var onSubmit = _a.onSubmit, className = _a.className, stickyFooter = _a.stickyFooter;
    var _b = useState(null), successMsg = _b[0], setSuccessMsg = _b[1];
    var _c = useState(null), errorMsg = _c[0], setErrorMsg = _c[1];
    var _d = useState(false), buttonDisabled = _d[0], setButtonDisabled = _d[1];
    var onSubmitWrapper = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    setButtonDisabled(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, onSubmit()];
                case 2:
                    _a.sent();
                    setSuccessMsg('Worked a treat!');
                    setButtonDisabled(false);
                    return [4 /*yield*/, delay(5 * 1000)];
                case 3:
                    _a.sent();
                    setSuccessMsg(null);
                    return [3 /*break*/, 5];
                case 4:
                    err_1 = _a.sent();
                    setErrorMsg(err_1.message || 'Unknown Error');
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var onAlertClose = function () {
        setSuccessMsg(null);
        setErrorMsg(null);
        setButtonDisabled(false);
    };
    return (_jsxs("div", __assign({ className: clx('form-footer', stickyFooter && 'form-sticky-footer') }, { children: [_jsx("div", __assign({ className: "form-buttons" }, { children: _jsx(Button, __assign({ variant: "contained", onClick: onSubmitWrapper, className: "loading-btn", disabled: buttonDisabled }, { children: "Submit" }), void 0) }), void 0), _jsxs("div", __assign({ className: "form-feedback" }, { children: [successMsg && (_jsx(Alert, __assign({ severity: "success", icon: _jsx(ThumbsUpIcon, { fontSize: "inherit" }, void 0), onClose: onAlertClose }, { children: successMsg }), void 0)), errorMsg && (_jsx(Alert, __assign({ severity: "error", icon: _jsx(SadFaceIcon, { fontSize: "inherit" }, void 0), onClose: onAlertClose }, { children: errorMsg }), void 0))] }), void 0)] }), void 0));
};
export default SubmitButton;
