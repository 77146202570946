var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Box, TextField } from '@material-ui/core';
import { cloneMstDataReferences, getEmptyMstReference } from '@common/types/MstData';
import { getPhotoThumb } from '@common/utils/photoIDUtils';
import { getFilesUrl } from '@common/constants';
import { fetchPath, optionsPOST as POST, headersJSON as hJSON } from '../../services/fetch';
import DataContext from '../../context/DataContext';
import SubmitButton from '../Form/SubmitButton';
import ReorderList from '../ReorderList/ReorderList';
import PhotoSelectModal from '../PhotoSelectModal/PhotoSelectModal';
import './styles.css';
var ReferenceList = function () {
    var _a = useContext(DataContext), data = _a.data, updateData = _a.update;
    var _b = useState(cloneMstDataReferences(data.references)), stateReferences = _b[0], setReferences = _b[1];
    var references = cloneMstDataReferences(stateReferences);
    var onSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var jsonStr;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    jsonStr = JSON.stringify(references);
                    return [4 /*yield*/, fetchPath('api/references', POST(jsonStr, hJSON))];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, updateData()];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(_Fragment, { children: [_jsx(ReorderList, { stickyKey: "references", items: references, onChange: function (items) { return setReferences(items); }, getEmptyItem: function () { return getEmptyMstReference(uuid()); }, itemFields: function (reference, index, onValueChange, setValues) {
                    return (_jsxs(_Fragment, { children: [_jsx(TextField, { type: "text", variant: "outlined", name: "text", label: "Reference Text", value: reference.text, onChange: onValueChange(reference, 'text'), fullWidth: true, multiline: true, rows: 6, rowsMax: 6 }, void 0), _jsxs(Box, __assign({ width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }, { children: [_jsx(TextField, { type: "text", variant: "outlined", name: "model_name", label: "Model Name", value: reference.model_name, onChange: onValueChange(reference, 'model_name'), fullWidth: true, style: { marginBottom: '1em' } }, void 0), _jsx(TextField, { type: "text", variant: "outlined", name: "model_url", label: "Model URL", value: reference.model_url, onChange: onValueChange(reference, 'model_url'), fullWidth: true }, void 0)] }), void 0), _jsxs(Box, __assign({ className: "reference-list-photo" }, { children: [_jsx(PhotoSelectModal, { selected: { album: reference.img_album, photo: reference.img_photo }, singleSelect: true, onApprove: function (photo) { return setValues(reference, ['img_album', photo.album], ['img_photo', photo.photo]); } }, void 0), reference.img_photo && _jsx("img", { src: getFilesUrl() + getPhotoThumb(data.albums, reference.img_album, reference.img_photo), alt: reference.model_name, className: "reference-image" }, void 0)] }), void 0)] }, void 0));
                } }, void 0), _jsx(SubmitButton, { onSubmit: onSubmit }, void 0)] }, void 0));
};
export default ReferenceList;
