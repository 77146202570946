import { jsx as _jsx } from "react/jsx-runtime";
import { ALBUM_FLAG_CONFIGS } from '@client/constants/flagConstants';
import { useFilterControl } from './FilterControl';
import UnifiedFilterControl from './UnifiedFilterControl';
export var useAlbumFlagFilterControl = function (key) {
    var states = [];
    for (var _i = 0, ALBUM_FLAG_CONFIGS_1 = ALBUM_FLAG_CONFIGS; _i < ALBUM_FLAG_CONFIGS_1.length; _i++) {
        var config = ALBUM_FLAG_CONFIGS_1[_i];
        // eslint-disable-next-line react-hooks/rules-of-hooks
        states.push(useFilterControl(key + ':' + config.name, null));
    }
    return states;
};
export var filterByAlbumFlagControl = function (states) {
    var selectedState = states.find(function (state) { return state[0] === true; });
    var selectedIndex = states.indexOf(selectedState);
    var selectedConfig = ALBUM_FLAG_CONFIGS[selectedIndex];
    return function (item) { return (selectedConfig ? item[selectedConfig.name] : true); };
};
/**
 *
 * Example
 *
 * const albumFlagFilter = useAlbumFlagFilterControl('unique-key');
 *
 * let shownAlbums = albums.filter(filterByAlbumFlagControl(albumFlagFilter));
 *
 * return <>
 *    <AlbumFlagFilterControl states={albumFlagFilter} />
 *    {filtered.map(item => <></>)}
 * </>
 */
export var AlbumFlagFilterControl = function (_a) {
    var states = _a.states, label = _a.label, off = _a.off;
    var items = ALBUM_FLAG_CONFIGS.map(function (config, index) { return ({
        state: states[index],
        label: config.label,
        icon: _jsx(config.on, {}, void 0),
        color: "var(--".concat(config.color, "-rgb)"),
    }); });
    return _jsx(UnifiedFilterControl, { items: items, label: label || 'Album Flags', off: off }, void 0);
};
